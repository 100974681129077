import { captureRemixErrorBoundaryError } from "@sentry/remix";
import {
  Links,
  Meta,
  Outlet,
  Scripts,
  ScrollRestoration,
  useRouteError
} from "@remix-run/react";
import { json } from "@remix-run/node";
import StoreProvider from "./commons/utils/state/config/Provider";
import { SomethingWentWrong } from "~/commons/components/SomethingWentWrong";

// Load the GA tracking id from the .env
export const loader = async () => {
  return json({ gaTrackingId: process.env.GA_TRACKING_ID });
};

export const ErrorBoundary = () => {
  const error = useRouteError();
  captureRemixErrorBoundaryError(error);
  return <SomethingWentWrong />;
};

export default function App() {
  return (
    <html>
      <head>
        <title>Rankify</title>
        <meta charSet="utf-8" />
        <meta name="viewport" content="width=device-width,initial-scale=1" />
        {/* <meta name="shopify-api-key" content={process.env.APP_SECRET_KEY} /> */}
        <link rel="preconnect" href="https://cdn.shopify.com/" />
        <link
          rel="stylesheet"
          href="https://cdn.shopify.com/static/fonts/inter/v4/styles.css"
        />
        <Meta />
        <Links />
      </head>
      <body>
        <StoreProvider>
          <Outlet />
        </StoreProvider>
        <ScrollRestoration />
        <Scripts />
        <script src="https://cdn.shopify.com/shopifycloud/app-bridge.js"></script>
      </body>
    </html>
  );
}
