interface Props {
  title?: string;
  message?: string;
  onRetry?: any;
}

export const SomethingWentWrong = ({ title, message, onRetry }: Props) => {
  const containerStyle = {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    minHeight: "90vh",
    backgroundColor: "#fffbe6", // Soft yellow background for warning theme
    padding: "16px",
    overflow: "hidden"
  };

  const errorContainerStyle = {
    backgroundColor: "#fff",
    boxShadow: "0 4px 20px rgba(0, 0, 0, 0.1)",
    borderRadius: "12px",
    padding: "32px 24px",
    maxWidth: "480px",
    textAlign: "center",
    border: "4px solid #f59e0b", // Warning-themed orange border
    position: "relative",
    overflow: "hidden"
  };

  const iconStyle = {
    width: "64px",
    height: "64px",
    fill: "#f59e0b", // Warning-themed orange color
    strokeWidth: "2"
  };

  const titleStyle = {
    fontSize: "1.75rem",
    fontWeight: "bold",
    color: "#4b5563",
    marginTop: "24px"
  };

  const messageStyle = {
    fontSize: "1rem",
    color: "#6b7280",
    marginTop: "12px",
    lineHeight: "1.5"
  };

  const buttonStyle = {
    display: "inline-block",
    marginTop: "24px",
    padding: "10px 20px",
    backgroundColor: "#f59e0b", // Warning-themed orange button
    color: "#fff",
    fontSize: "1rem",
    fontWeight: "bold",
    border: "none",
    borderRadius: "8px",
    cursor: "pointer",
    transition: "background-color 0.3s ease"
  };

  const footerStyle = {
    fontSize: "0.9rem",
    color: "#6b7280",
    marginTop: "24px",
    lineHeight: "1.5"
  };

  const linkStyle = {
    color: "#1d4ed8",
    textDecoration: "underline",
    cursor: "pointer"
  };

  return (
    <div style={containerStyle}>
      <div style={errorContainerStyle}>
        {/* Warning Icon */}
        <svg
          xmlns="http://www.w3.org/2000/svg"
          xmlns:xlink="http://www.w3.org/1999/xlink"
          fill="#000000"
          version="1.1"
          id="Capa_1"
          width="50px"
          height="50px"
          viewBox="0 0 478.125 478.125"
          xmlSpace="preserve"
          style={iconStyle}
        >
          <g>
            <g>
              <g>
                <circle cx="239.904" cy="314.721" r="35.878" />
                <path d="M256.657,127.525h-31.9c-10.557,0-19.125,8.645-19.125,19.125v101.975c0,10.48,8.645,19.125,19.125,19.125h31.9     c10.48,0,19.125-8.645,19.125-19.125V146.65C275.782,136.17,267.138,127.525,256.657,127.525z" />
                <path d="M239.062,0C106.947,0,0,106.947,0,239.062s106.947,239.062,239.062,239.062c132.115,0,239.062-106.947,239.062-239.062     S371.178,0,239.062,0z M239.292,409.734c-94.171,0-170.595-76.348-170.595-170.596c0-94.248,76.347-170.595,170.595-170.595     s170.595,76.347,170.595,170.595C409.887,333.387,333.464,409.734,239.292,409.734z" />
              </g>
            </g>
          </g>
        </svg>
        {/* Title */}
        <h1 style={titleStyle}>
          {title || "Uh-oh! Something needs your attention."}
        </h1>
        {/* Message */}
        <p style={messageStyle}>
          {message ||
            "It seems like we've encountered a minor issue. Don't worry, it's probably something we can fix quickly."}
        </p>
        {/* Retry Button */}
        {onRetry && (
          <button
            style={buttonStyle}
            onMouseOver={(e) => (e.target.style.backgroundColor = "#eab308")}
            onMouseOut={(e) => (e.target.style.backgroundColor = "#f59e0b")}
            onClick={onRetry}
          >
            Try Again
          </button>
        )}
        {/* Footer */}
        <p style={footerStyle}>
          Still seeing this? Refresh the page or{" "}
          <a
            href="mailto:support@xco.agency"
            target="_blank"
            style={linkStyle}
            rel="noreferrer"
          >
            drop us a line at support@xco.agency
          </a>{" "}
          and we'll get it sorted for you!
        </p>
      </div>
    </div>
  );
};
